import {
  addDays,
  addHours,
  endOfMonth,
  endOfWeek as endOfWeekWithOpts,
  format as formatWithOptions,
  getDay,
  startOfWeek as startOfWeekWithOpts,
} from 'date-fns'
import deLocale from 'date-fns/locale/de'

import { Datestamp, isDatestamp } from 'customers/data/Datestamp'
import { Timestamp } from 'customers/data/Timestamp'
import { toDateObject } from 'customers/data/toDateObject'

const locale = deLocale
const weekStartsOn = 1 as const

const dayCache = {
  dayNames: recalcDateNames(),
  dayRanges: {} as { [key: string]: Date[] },
}

// export function setUserLocale(newLocale: string, newWeekStartsOn: 0 | 1) {
//   weekStartsOn = newWeekStartsOn
//   locale = newLocale
//   dayCache.dayNames = recalcDateNames()
//   dayCache.dayRanges = {}
// }

export function dayNames(): string[] {
  return dayCache.dayNames
}

export function formatDate(
  date: Date | Datestamp | Timestamp,
  formatString = 'dd.MM.yyyy',
): string {
  return formatWithOptions(toDateObject(date), formatString, { locale })
}

export function humanDate(date: Date | Timestamp | Datestamp): string {
  return formatDate(toDateObject(date), 'dd.MM.yyyy')
}

export function humanDatetime(date: Date | Timestamp | Datestamp): string {
  const fmt = 'dd.MM.yyyy - HH:mm'
  if (date instanceof Date) {
    return formatDate(date, fmt)
  } else if (isDatestamp(date)) {
    return formatDate((date + 'T12:00:00Z') as Timestamp, fmt)
  } else {
    return formatDate(date, fmt)
  }
}

export function startOfWeek(date: Date): Date {
  return startOfWeekWithOpts(date, { weekStartsOn })
}

export function endOfWeek(date: Date): Date {
  return endOfWeekWithOpts(date, { weekStartsOn })
}

function recalcDateNames() {
  const strings: string[] = []
  let d = startOfWeek(new Date())
  do {
    strings.push(formatDate(d, 'E'))
    d = addDays(d, 1)
  } while (getDay(d) !== weekStartsOn)
  return strings
}

export function dayRangeForMonth(year: number, month: number): Date[] {
  const cacheKey = `${year}-${month}`

  if (!(cacheKey in dayCache.dayRanges)) {
    let day = new Date(year, month, 1, 12)
    const end = endOfWeek(endOfMonth(day))
    day = addHours(startOfWeek(day), 12)

    const days: Date[] = []

    while (day <= end) {
      days.push(day)
      day = addDays(day, 1)
    }

    dayCache.dayRanges[cacheKey] = days
  }

  return dayCache.dayRanges[cacheKey]
}

export function formatCurrency(amount: number) {
  if (typeof amount !== 'number') {
    return '?'
  }

  const isNegative = amount < 0
  const [integral, decimal] = amount.toFixed(2).split('.')
  let out = ',' + decimal + ' €'
  for (let last = integral.length - 1, i = last; i >= (isNegative ? 1 : 0); i--) {
    if (last !== i && (last - i) % 3 === 0) {
      out = '.' + out
    }
    out = integral[i] + out
  }
  if (isNegative) {
    out = '-' + out
  }
  return out
}
