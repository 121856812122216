import { ReactNode } from 'react'
import { border, color, radius, shadow, space } from '@dropscan/ds/tokens'

import { Heading } from '@dropscan/ds/components/Heading'
import { Box } from '@dropscan/ds/core/Box'
import SvgLogo from '@dropscan/ds/icons/Logo'
import { SolidButtons } from '@dropscan/ds/forms/Button'
import styled from '@dropscan/ds/styled'
import { MediaQueries } from '@dropscan/ds/ScreenSize'

import Footer from 'customers/layouts/SettingsLayout/Footer'

export function LoginLayout({ heading, children }: { heading?: string; children: ReactNode }) {
  return (
    <FullscreenContainer>
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        {heading && (
          <Heading m={0} mb={4} level={1} css={{ textAlign: 'center' }}>
            {heading}
          </Heading>
        )}
        <SolidButtons>{children}</SolidButtons>
        <Box mx="auto" mt={7}>
          <SvgLogo />
        </Box>
      </div>
      <Footer />
    </FullscreenContainer>
  )
}

const FullscreenContainer = styled.div({
  position: 'fixed',
  height: '100vh',
  width: '100vw',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: color('gray-900'),
  overflow: 'scroll',
  WebkitOverflowScrolling: 'touch',
  alignItems: 'center',

  [MediaQueries.Small]: {
    paddingTop: 0,
  },
})

export const ContentSizer = styled.div({
  [MediaQueries.Small]: {
    paddingLeft: 0,
    paddingRight: 0,
    width: '100%',
  },

  [MediaQueries.Medium]: {
    paddingLeft: space(8),
    paddingRight: space(8),
  },

  [MediaQueries.Large]: {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: `calc(1024px - 2 * ${space(8)})`,
    paddingLeft: 0,
    paddingRight: 0,
  },
})

export const FormContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridRowGap: space(4),
  padding: space(7),
  backgroundColor: color('white'),
  borderRadius: radius(1),
  border: border('light'),
  boxShadow: shadow('medium'),
  minWidth: 400,
  maxWidth: 600,
  [MediaQueries.Small]: {
    width: '100vw',
    minWidth: 0,
    margin: 0,
    borderRadius: 0,
    border: 'none',
  },
})

export const FormSection = styled.div({
  borderBottom: border('light'),

  '&:last-child': {
    borderBottom: 'none',
  },

  padding: space(6),

  '& > *': {
    marginBottom: space(4),
  },

  '& > *:last-child': {
    marginBottom: 0,
  },
  [MediaQueries.Small]: {
    width: '100%',
    '& > *': {
      flexBasis: '100vw',
    },
  },
})
