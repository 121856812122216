import { keyframes } from '@emotion/react'

import styled from '../styled'

interface Props {
  height: string
}

const spin = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(359deg)',
  },
})

/**
 * A circular animated spinner. Use the `height` prop to set the size.
 *
 * @component
 */
export const Spinner = styled.div<Props>(
  {
    display: 'inline-block',
    animation: `${spin} 1s infinite linear`,
    ':before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      height: '100%',
      width: '100%',
      borderRadius: '100%',
      borderStyle: 'solid',
      borderColor: 'transparent currentcolor currentcolor transparent',
    },
  },
  ({ height = 60 }) => {
    return {
      height,
      width: height,
      ':before': {
        left: '-3px',
        top: '-3px',
        borderWidth: '-3px',
      },
    }
  },
)

export default Spinner
