export default function findCSRFToken(): string {
  if (typeof document === 'undefined') {
    return ''
  }
  const metaTag = document.head.querySelector('meta[name="csrf-token"]')
  if (!metaTag) {
    if (process.env.NODE_ENV !== 'production') {
      console.error('csrf-token meta tag not found')
    }
    return ''
  }
  return metaTag.getAttribute('content') || ''
}
