import * as React from 'react'

import findCSRFToken from './findCSRFToken'
import { Errors, Form, useForm, Validator } from './useForm'

interface Props<Data extends object, VO = {}> {
  /** The initial data to start out the form. This data should be structurally valid (have the correct type) */
  initialData: Data
  initialErrors?: Errors<Data>
  action: string
  children: (props: Form<Data, VO>) => React.ReactElement
  validate?: Validator<Data, VO>
  className?: string
}

export default function HtmlForm<Data extends object>({
  action,
  children,
  className,
  ...props
}: Props<Data>) {
  const formElement = React.useRef<HTMLFormElement | null>(null)
  const onSubmit = React.useCallback(async () => {
    if (!formElement.current) {
      return
    }
    const inputs = Array.from(formElement.current.elements) as HTMLInputElement[]
    let hasErrors = false
    inputs.forEach(input => {
      // FormController disables inputs, which would normally cause them to be
      // omitted from the request payload, so we re-enable them immediately
      // before submitting.
      input.disabled = input.tagName === 'BUTTON'
      hasErrors = hasErrors || !input.checkValidity()
    })

    formElement.current.submit()

    if (hasErrors) {
      return Promise.resolve()
    } else {
      return new Promise<void>(() => {
        /* never resolve, the normal form submission will cause a reload */
      })
    }
  }, [])

  const form = useForm({
    ...props,
    onSubmit,
  })

  return (
    <form ref={formElement} method="post" action={action} className={className}>
      <input
        type="hidden"
        style={{ display: 'none' }}
        name="authenticity_token"
        value={findCSRFToken()}
      />
      {children(form)}
    </form>
  )
}
