import { CSSObject } from '@emotion/react'
import * as React from 'react'

import Spinner from '../components/Spinner'
import { Button, Props as ButtonProps, SizeStyles } from './Button'

export interface Props extends ButtonProps {
  id?: string
  /** When true, the button will show a spinner */
  running?: boolean
  styles?: CSSObject
  children?: React.ReactNode
  onClick?: (evt: React.MouseEvent) => void
  disabled?: boolean
  innerRef?: (el: HTMLButtonElement) => void
}

/**
 * `SpinnerButton` is a specialized `Button` that supports an extra `running` property. When `running` is true the button will be disabled and it's content replaced with a [`Spinner`](#spinner).
 */
export default React.forwardRef(function SpinnerButton(
  { running, children, disabled, ...props }: Props,
  ref: React.Ref<HTMLButtonElement>,
) {
  const spinnerHeight = SizeStyles[props.size || 'normal'].fontSize
  return (
    <Button ref={ref} {...props} disabled={running || disabled}>
      {running ? <Spinner height={spinnerHeight} /> : children}
    </Button>
  )
})
